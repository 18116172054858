.market{
    padding-top: 234px;
    padding-bottom: 100px;
}
.market .market-title{
    font-size: 65px;
    font-weight: 700;
    line-height: 78px;
    text-align: right;
}
.market .location img,
.market .phone img{
    margin-left: 28px;
}
.market .location,
.market .phone{
    color: #767676;
    font-size: 32px;
}
.l-hover:hover{
    color: var(--primary);
}
.market .img{
    max-width: 1270px;
    max-height: 831px;
    border-radius: 16px;
    margin-top: 60px;
}
@media screen and (max-width: 500px){
    .market{
        padding-top: 134px;
    }
    .market .market-title{
        font-size: 32px;
        line-height: 30px;
        margin-bottom: 12px;
    }
    .market .location,
    .market .phone{
        color: #767676;
        font-size: 20px;
    }
    .market .location img,
    .market .phone img{
        margin-left: 12px;
    }
}