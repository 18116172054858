.all-markets{
    padding-top: 150px;
    background-color: #FDFDFD;
    min-height: 75vh;
}

.all-markets .title{
    text-align: center;
    font-size: 65px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}

.all-markets .title::before,
.all-markets .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.all-markets .title::before{
    right: 23%
}
.all-markets .title::after{
    left: 23%
}

.all-markets .allmarket-card{
    font-family: "Tajawal", sans-serif !important;
    text-align: right;
    direction: rtl;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px #0000000F;
    cursor: pointer;
}
.all-markets .new-card .card-description{
    color: #ADADAD;
    margin-bottom: 0;
}
.all-markets .allmarket-card img{
    border-radius: 3px;
}


.allmarket-card .hours{
    color: #767676;
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
    /* padding-bottom: 16px!important; */
    margin-bottom: 0;
}
.allmarket-card .card-title{
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 17px;
    /* margin-top: 16px!important; */
}
.allmarket-card .card-location{
    margin-bottom: 18px;
}
.allmarket-card .card-phone{
    margin-bottom: 0;
}
.allmarket-card .card-location img,
.allmarket-card .card-phone img{
    margin-left: 12px;
}
.allmarket-card .card-location,
.allmarket-card .card-phone{
    font-size: 18px;
    font-weight: 700;
    color: #767676;
}
.allmarket-card:hover .card-location,
.allmarket-card:hover .hours,
.allmarket-card:hover .card-phone{
    color: #fff;
}
.all-markets .allmarket-card:hover{
    background-color: var(--primary);
    color: #fff!important;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .all-markets{
        padding-top: 60px
    }
    .all-markets .title{
        font-size: 40px;
        margin-bottom: 32px;
    }
    .all-markets .title::before{
        right: 10%
    }
    .all-markets .title::after{
        left: 10%
    }
    .all-markets .p-2{
        padding: 0!important;
    }
    .all-markets .m-4{
        margin: 0!important;
    }
    .market-card .hours {
        font-size: 16px;
    }
    hr {
        margin: 0.5rem 0
    }
    .all-markets .allmarket-card .card-title {
        font-size: 22px;
    }
    .all-markets .allmarket-card .card-location, 
    .all-markets .allmarket-card .card-phone {
        font-size: 16px;
    }
    .all-markets .allmarket-card .card-location img, 
    .all-markets .allmarket-card .card-phone img{
        width: 30px;
        height: 30px;
    }
}
@media screen and (max-width: 500px){
    .all-markets{
        padding-top: 70px;
    }
    .all-markets .title{
        font-size: 24px;
    }
    .all-markets .title::before,
    .all-markets .title::after{
        width: 50px;
    }
    .all-markets .title::before{
        right: 8%
    }
    .all-markets .title::after{
        left: 8%
    }
}