.carousel-indicators button{
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
    background-color: #fff !important;
}
.carousel-indicators .active{
    background-color: var(--primary) !important;
}
.carousel-inner .carousel-item img{
    height: 650px;
}
.carousel-caption{
    right: 8%;
    width: 45%;
    text-align: right;
    top: 50%;
    bottom: auto;
    transform: translate(0, -50%);
}
.carousel-caption h1{
    font-size: 65px;
    color: #fff;
    font-weight: 700;
}
.carousel-caption a{
    font-weight: 700;
    background-color: var(--primary);
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 24px 77.2px;
    margin-top: 24px;
    border-radius: 8px;
}
.carousel-caption p{
    font-size: 28px;
    color: #fff;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 2.5rem;
}
.carousel-control-prev,
.carousel-control-next{
    display: none;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .carousel-caption{
        width: 50%;
    }
    .carousel-caption h1{
        font-size: 40px;
        margin-bottom: 8px;
    }
    .carousel-caption p{
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
    }
}
@media screen and (max-width: 500px){
    .carousel{
        margin-top: 86px;
    }
    .carousel-indicators {
        bottom: -18px;
    }
    .carousel-indicators button{
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        background-color: #fff !important;
    }
    .carousel-inner .carousel-item img{
        height: 260px;
    }
    .carousel-caption h1{
        font-size: 18px;
    }
    .carousel-caption p{
        font-size: 15px;
        font-weight: 500;
        line-height: 17.77px;
    }
    .carousel-caption a{
        padding: 12px 16px;
        font-size: 16px;
    }
}