.management{
    overflow: hidden;
    margin-top: 81px;
    padding-top: 66px;
    padding-bottom: 120px;
    position: relative;
    background: radial-gradient(50% 50% at 50% 50%, #2A5EB2 0%, #0E3C86 100%);
}
.management::after{
    content: '';
    width: 269px;
    height: 269px;
    background: #FFFFFF1F;
    border-radius: 50%;
    position: absolute;
    left: -55px;
    top: 655px;
}
.management::before{
    content: '';
    width: 138px;
    height: 138px;
    background: #FFF9F921;
    border-radius: 50%;
    position: absolute;
    left: 104px;
    top: 630px;
}
.management .title{
    text-align: center;
    font-size: 65px;
    font-weight: 700;
    color: #fff;
    position: relative;
}

.management .title::before,
.management .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: #fff;
}
.management .title::before{
    right: 27%
}
.management .title::after{
    left: 27%
}
.p-carousel-indicators{
    display: none;
}
.management .p-carousel-next,
.management .p-carousel-prev{
    box-shadow: 0px 0px 16px 0px #0000000F;
    border-radius: 50%;
    background-color: #fff;
}
.management .management-card{
    font-family: "Tajawal", sans-serif !important;
    text-align: right;
    direction: rtl;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px #0000000F;
}

.management .management-card .card-description{
    color: #ADADAD;
    margin-bottom: 0;
}
.management .management-card img{
    border-radius: 12px;
    height: 330px;
    width: 100%
}

.management .management-card .card-name{
    font-size: 18px;
    font-weight: 700;
    margin-top: 22px;
    margin-bottom: 4px;
}
.management .management-card .card-jop{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .management{
        padding-top: 60px
    }
    .management::after {
        top: auto;
        left: -80px;
        bottom: -28%;
    }
    .management::before {
        left: -80px;
        top: 11%;
    }
    .management .title{
        font-size: 40px;
    }
    .management .title::before{
        right: 10%
    }
    .management .title::after{
        left: 10%
    }
    .management .p-carousel .p-carousel-item .m-4{
        margin: 8px!important;
    }
    .management .management-card img{
        height: 268px!important;
    }
}
@media screen and (max-width: 500px){
    .management{
        padding-bottom: 30px;
    }
    .management .title{
        font-size: 16px;
    }
    .management .title::before,
    .management .title::after{
        width: 65px;
    }
    .management .title::before{
        right: 12%
    }
    .management .title::after{
        left: 12%
    }
    .management::after,
    .management::before{
        content: none;
    }
    .management .management-card img{
        width: 100%!important;
    }
}