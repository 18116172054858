.contact{
    background-color: #FDFDFD;
    padding-top: 140px;
    min-height: 75vh;
}
.contact .card{
    border: none;
    /* o/pacity: 0; */
    animation: fade 1s ease-in;
}
@keyframes fade {
    from {opacity: 0}
  to {opacity: 1}
}
.contact .info{
    background-color: var(--primary);
    /* width: 490px; */
    border-radius: 8px;
    color: #fff;
    padding: 40px;
    position: relative;
    overflow: hidden;
}
.contact .info::after{
    content: '';
    width: 269px;
    height: 269px;
    background: #FFFFFF1F;
    border-radius: 50%;
    position: absolute;
    left: -55px;
    top: 464px;
}
.contact .info::before{
    content: '';
    width: 138px;
    height: 138px;
    background: #FFF9F921;
    border-radius: 50%;
    position: absolute;
    left: 104px;
    top: 438px;
}
.contact .info h3{
    font-size: 24px;
    font-weight: 700;
    
}
.contact .info p{
    font-size: 18px;
    font-weight: 500;
    color: #ADADAD;
}
.contact .title{
    font-size: 65px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
    text-align: center;
}

.contact .title::before,
.contact .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.contact .title::before{
    right: 24%
}
.contact .title::after{
    left: 24%
}

.contact .contacts img{
    margin-left: 8px;
}
.contact .contacts p{
    color: #fff;
    margin-bottom: 50px;
}
.contact .contacts{
    margin-top: 114px;
    margin-bottom: 225px;
}
.contact .form{
    padding: 90px 50px 0 50px;
}
.contact .form label{
    display: block;
}
.contact .form input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #ADADAD
}
.contact .form .submit{
    text-align: end;
}
.contact .form .submit button{
    background-color: #174B9F;
    padding: 15px 48px 15px 48px;
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
}
.contact .form input:focus-visible{
    outline: none;
    border: none;
    border-bottom: 1px solid var(--primary)
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .contact{
        padding-top: 120px;
    }
    .contact .title{
        font-size: 40px;
    }
    .contact .title::before{
        right: 19%
    }
    .contact .title::after{
        left: 19%
    }
    .contact .info {
        padding: 40px 20px;
    }
    .contact .info h3 {
        font-size: 20px;
    }
    .contact .info p {
        font-size: 16px;
    }
    .contact .contacts {
        margin-top: 50px;
        margin-bottom: 0;
    }
    .contact .contacts p {
        margin-bottom: 18px;
    }   
    .contact .info::after {
        width: 170px;
        height: 170px;
        left: -55px;
        top: 422px;
    }
    .contact .info::before {
        width: 100px;
        height: 100px;
        left: 55px;
        top: 380px;
    }
    .contact .form {
        padding: 60px 25px 0 10px;
    }
    .contact .mb-5 {
        margin-bottom: 1rem!important;
    }
}
@media screen and (max-width: 500px) {
    .contact{
        padding: 120px 0 0;
    }
    .contact .form .submit button{
        width: 100%;
    }
    .contact .title{
        font-size: 18px;
    }
    .contact .title::before{
        right: 9%
    }
    .contact .title::after{
        left: 9%
    }
    .contact .contacts{
        margin-top: 36px;
        margin-bottom: 90px;
    }
    .contact .contacts p{
        margin-bottom: 18px;
    }
    .contact .info::after{
        width: 50%;
        height: 162px;
        left: -55px;
        top: 74%;
    }
    .contact .info::before{
        width: 32%;
        height: 119px;
        left: 74px;
        top: 260px;
    }
    .contact .form{
        padding: 59px 12px 0 12px;
    }
    .contact .form label{
        color: #ADADAD;
    }
    .contact .form .mb-5{
        margin-bottom: 1.3rem!important;
    }
}