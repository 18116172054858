.privacy{
    padding-top: 150px;
    background-color: #FDFDFD;
}

.privacy .title{
    text-align: center;
    font-size: 65px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}

.privacy .title::before,
.privacy .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.privacy .title::before{
    right: 19%
}
.privacy .title::after{
    left: 19%
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .privacy{
        padding-top: 120px
    }
    .privacy .title{
        font-size: 40px;
        margin-bottom: 32px;
    }
    .privacy .title::before{
        right: 10%
    }
    .privacy .title::after{
        left: 10%
    }
}
@media screen and (max-width: 500px){
    .privacy{
        padding-top: 120px;
    }
    .privacy .title{
        font-size: 24px;
    }
    .privacy .title::before,
    .privacy .title::after{
        width: 50px;
    }
    .privacy .title::before{
        right: 8%
    }
    .privacy .title::after{
        left: 8%
    }
}