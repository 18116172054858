.talabat{
    padding: 57px 0;
    background: #174B9F;
    position: relative;
    overflow: hidden;
}
.talabat::after{
    content: '';
    width: 269px;
    height: 269px;
    background: #FFFFFF1F;
    border-radius: 50%;
    position: absolute;
    left: -167px;
    top: 247.38px;
}
.talabat::before{
    content: '';
    width: 138px;
    height: 138px;
    background: #FFF9F921;
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: 221.38px;
}
.talabat .talabat-img{
    width: 364px;
    height: 273px;
    border-radius: 20px;
}
.talabat .title{
    font-size: 65px;
    font-weight: 700;
    color: #fff;
    line-height: 78px;
    padding: 0 46px;
}
.talabat .order-now{
    padding: 0 80px;
    text-align: end;
}
.talabat .order-now a{
    background-color: #FF6F00;
    text-decoration: none;
    padding: 18px 48px;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-weight: 700;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .talabat img{
        width: 100%!important;
        height: 100%!important;
    }
    .talabat .title {
        font-size: 40px;
        line-height: 50px;
    }
}
@media screen and (max-width: 500px){
    .talabat img{
        width: 100%!important;
    }
    .talabat .title{
        font-size: 32px;
        line-height: 38.6px;
        margin-top: 37px;
        text-align: center;
    }
    .talabat .order-now button{
        background: none;
        width: 100%;
        border: 1px solid #fff;
        margin-top: 37px;
    }
    .talabat::after,
    .talabat::before{
        content: none;
    }
}